import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core'
import { AuthService } from '@services/auth.service'
import { ContextService } from '@services/context.service'
import { LoggingService } from '@services/logging.service'
import { OrganisationService } from '@services/organisation.service'
import { RequestedBookingsCountPublisher } from '@app/domain/RequestedBookingsCountPublisher'
import { Router } from '@angular/router'
import { Subject, forkJoin, takeUntil, first } from 'rxjs'
import { ToolbarService } from '@services/toolbar.service'
import { UpdateService } from '@services/update.service'
import { UserService } from '@services/user.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'TableSense'
    private onDestroy$ = new Subject<void>()

    constructor(
        private appRef: ApplicationRef,
        private authService: AuthService,
        private contextService: ContextService,
        private loggingService: LoggingService,
        private organisationService: OrganisationService,
        private requestedBookingsCountPublisher: RequestedBookingsCountPublisher,
        public toolbarService: ToolbarService,
        private updateService: UpdateService,
        private userService: UserService,
        public router: Router
    ) {
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable))
        appIsStable$.subscribe(() => this.loggingService.setUp())
    }

    ngOnInit() {
        this.updateService.startCheckingForUpdates()
        this.authService.loggedIn$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(loggedIn => {
                if (loggedIn) {
                    this.initialiseStack()
                    this.requestedBookingsCountPublisher.startPollingForRequestedBookings()
                }
            })
        this.authService.checkLoggedInStatus()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    private initialiseStack() {
        forkJoin([
            this.userService.get(),
            this.organisationService.get(),
        ])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(([user, organisation]) => {
                this.contextService.setUpObjectGraph(organisation, user)
            })
    }
}
